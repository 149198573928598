import { useEffect } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { useAuth0 } from "@auth0/auth0-react";

import { setToken, setNickname, setPicture } from './app/authSlice';

import { List, Create, Edit, Login } from './features';

import LinearProgress from '@mui/material/LinearProgress';

const App = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    getIdTokenClaims().then((data) => {
      if (data &&  '__raw' in data) {
        dispatch(setToken(data['__raw']))
        dispatch(setPicture(data.picture))
        dispatch(setNickname(data.nickname))        
      }
    })
  }, [isAuthenticated, isLoading])

  if (isLoading) {
    return (<LinearProgress />)
  }
    
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/:path" element={<Login />} />
        <Route exact path="/list" element={<List />} />
        <Route exact path="/create" element={<Create />} />
        <Route path="/:id/:name" element={<Edit />} />
      </Routes>      
    </>    
  )
}

export default App;
