import { useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

export const Login = () => {
  const { path } = useParams()
  const navigate = useNavigate()
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();  
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    }
    if (isAuthenticated && path === 'callback') {
      navigate('/list')
    }
    else if (isAuthenticated && path === 'logout') {
      logout({returnTo: window.location.origin + '/logout'})
    }
    navigate('/list')
    // else {
    //   console.error(`The path ${path} in the URL not understood`)
    // }
  }, [isAuthenticated, isLoading])

  return (
    <LinearProgress />
  )
}
