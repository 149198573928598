import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../Header/Header';
import { CustomForm } from '../../components/CustomForm';

import { useUpdateMutation } from '../../api';

export const Edit = () => {
  const navigate = useNavigate()
  const { id, name } = useParams()
  const [ update, { isLoading: isUpdating }] = useUpdateMutation()

  const key = `${name}:${id}`;
  
  const submit = (id, name) => {
    const body = {
      name,
      id
    }
    update({key, payload: body})
      .unwrap()
      .then(() => {
        navigate('/list')
      })
      .then((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <Header/>
      <CustomForm label="Update" submit={submit} pname={name} pid={id}/>
    </>
  )
}
