import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';

import { useCreateMutation } from '../../api';
import { CustomForm } from '../../components/CustomForm';

export const Create = () => {
  const navigate = useNavigate()
  const [ create ] = useCreateMutation();
  
  const submit = (id, name) => {
    const body = {
      name,
      id
    }
    create(body)
      .unwrap()
      .then(() => {
        navigate('/list')
      })
      .then((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <Header />
      <CustomForm label="Create" submit={submit} />
    </>
  )
}
