import { useState } from 'react';
import { useGetAllQuery, useDeleteMutation } from '../../api';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const columns = [
  { field: 'id', headerName: 'ID', width: 500 },
  { 
    field: 'name',
    headerName: 'Name',
    width: 200
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 100,
    renderCell: () => (
      <Button
        variant="contained"
        color="warning"
        size="small"
        style={{ marginLeft: 16 }}
      >
        Delete
      </Button>            
    )
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 100,
    renderCell: () => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}        
      >
        Edit
      </Button>            
    )
  }  
];

export const List = () => {
  const [ errors, setErrors ] = useState()
  const navigate = useNavigate()
  /* eslint-disable no-restricted-globals */
  const [ deleteItem, { isLoading: isDeleting, isError: isDeleteError }] = useDeleteMutation()
  const handleDelete = (params) => {
    if (params.field === 'delete') {
      const confirmed = confirm('Are you sure?')
      if (confirmed) {
        const key = `${params.row.name}:${params.row.id}`
        deleteItem(key)
          .unwrap()
          .then((data) => {
            navigate('/list')
          }).catch((err) => {
            console.log(err)
            setErrors('Unable to delete')
          })
      }
    }
    else if (params.field === 'edit') {
      const key = `${params.row.name}:${params.row.id}`
      navigate(`/${params.row.id}/${params.row.name}`)
    }
  }
  const { data,
	  isLoading,
	  isFetching,
	  isError,
	  isUninitialized
	} = useGetAllQuery();
  if (isLoading || isFetching || isUninitialized || isError) {
    return (<LinearProgress />)
  }
  return (
    <>
      <Header />
      {
        isDeleteError && <Alert onClose={() => {}} severity="error"> Error while deleting </Alert>
      }
      <Fab
        onClick={() => navigate('/create')}
        sx={{position: 'absolute', bottom: 150, right: 8}}
        size="large" color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      <Box sx={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          onCellClick={handleDelete}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>        
    </>  
  )
}
