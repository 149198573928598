import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const API = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState()).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => {
        return {
          url: '/',
          method: 'GET',
        }
      },
      providesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    create: builder.mutation({
      query: (payload) => {
        return {
          url: '/',
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    delete: builder.mutation({
      query: (key) => {
        if (!localStorage.getItem('token')) {
          throw new Error('OK')
        }
        return {
          url: `/${key}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    update: builder.mutation({
      query: ({key, payload}) => {
        if (!localStorage.getItem('token')) {
          throw new Error('OK')
        }
        return {
          url: `/${key}`,
          method: 'PUT',
          body: payload,
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    })    
  })
})

export const { useGetAllQuery, useCreateMutation, useDeleteMutation, useUpdateMutation } = API;
