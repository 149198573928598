import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  token: null,
  email: null,
  nickname: null,
  picture: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
      state.isAuthenticated = true;
    },
    setEmail: (state, { payload }) => {
      state.email = payload;
    },
    setNickname: (state, { payload }) => {
      state.nickname = payload;
    },
    setPicture: (state, { payload }) => {
      state.picture = payload;
    }
  },
})

export const { setToken, setEmail, setNickname, setPicture } = authSlice.actions
export const selectedNickname = (state) => state.auth.nickname;
export const selectedPicture = (state) => state.auth.picture;
export default authSlice.reducer
